import React, { useState, useEffect } from 'react';

// Define the contact type based on the expected API response
interface Contact {
    _id: string;
    username: string;
    email: string;
    number: string;
    message: string;
}

const ManageContact: React.FC = () => {
    const [contacts, setContacts] = useState<Contact[]>([]); // Initialize contacts as an array of Contact objects
    const [error, setError] = useState<string | null>(null); // State to handle any errors

    useEffect(() => {
        fetchContacts();
    }, []);

    const fetchContacts = async () => {
        try {
            const response = await fetch('https://business-branding-4t1h.onrender.com/api/form/Contact');
            if (!response.ok) {
                setError('Failed to fetch contacts');
                console.error('Error fetching contacts:', response.statusText);
                return;
            }
            const data: Contact[] = await response.json(); // Expecting an array of Contact objects

            // Ensure data is an array before setting it
            if (Array.isArray(data)) {
                setContacts(data);
            } else {
                setError('Data format is incorrect');
                console.error('Data format error:', data);
            }
        } catch (error) {
            setError('Error fetching contacts');
            console.error('Error fetching contacts:', error);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await fetch(`https://business-branding-4t1h.onrender.com/api/form/Contacts/${id}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                setError('Failed to delete contact');
                console.error('Error deleting contact:', response.statusText);
                return;
            }
            fetchContacts();
        } catch (error) {
            setError('Error deleting contact');
            console.error('Error deleting contact:', error);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="container p-6 mx-auto bg-white rounded-lg shadow-lg bg-opacity-80 md:bg-opacity-100">
                <h2 className="mb-6 text-2xl font-extrabold text-center text-transparent text-blue-700 md:text-4xl bg-clip-text bg-gradient-to-r from-blue-500 via-red-700 to-blue-500 animate-fadeIn">
                    Manage Contacts
                </h2>
                {error && <p className="mb-4 text-center text-red-500">{error}</p>}
                {contacts.length === 0 && !error ? (
                    <p className="text-center text-gray-500">No contacts found.</p>
                ) : (
                    <ul className="space-y-6">
                        {contacts.map((contact) => (
                            <li
                                key={contact._id}
                                className="p-4 transition-shadow bg-white border border-gray-200 rounded-lg shadow-md md:p-6 hover:shadow-lg"
                            >
                                <div className="mb-2 text-sm font-semibold text-gray-800 md:text-lg">
                                    <strong>Full Name:</strong> {contact.username}
                                </div>
                                <div className="mb-2 text-sm md:text-gray-600">
                                    <strong>Email:</strong> {contact.email}
                                </div>
                                <div className="mb-2 text-sm md:text-gray-600">
                                    <strong>Number:</strong> {contact.number}
                                </div>
                                <div className="mb-4 text-sm md:text-gray-500">
                                    <strong>Message:</strong> {contact.message}
                                </div>
                                <button
                                    onClick={() => handleDelete(contact._id)}
                                    className="px-3 py-1 text-white transition-colors transform bg-red-600 rounded-lg shadow-md md:px-4 md:py-2 hover:bg-red-700 hover:scale-105"
                                >
                                    Delete
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default ManageContact;
