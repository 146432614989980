
import bidurgiri from '../../assets/bidur.jpg';
import bharat from '../../assets/bharat.jpeg';
import anish from '../../assets/anish1.jpg';
import daman from '../../assets/daman.jpg';
import susila from '../../assets/susila.jpg';
import lalan from '../../assets/lalan.jpg';
import junaid from '../../assets/junaid.jpg';
import sulav from '../../assets/sulav.jpg';

const defaultPhoto = bidurgiri;

// Define the type for the photo names
type PhotoName = "Anish Mainali" | "Daman Ghale" | "Susila Malla" | "Lalan Tharu" | "Junaid Sir" | "Sulav Bhandari" | "Bidur Giri" | "Bharat Giri";


function getPhoto(photo: PhotoName | undefined): string {
    switch (photo) {
        case 'Bidur Giri':
            return bidurgiri;
        case 'Bharat Giri':
            return bharat;
        case 'Anish Mainali':
            return anish;
        case 'Daman Ghale':
            return daman;
        case 'Susila Malla':
            return susila;
        case 'Lalan Tharu':
            return lalan;
        case 'Junaid Sir':
            return junaid;
        case 'Sulav Bhandari':
            return sulav;
        default:
            return defaultPhoto;
    }
}

// Define props for the TeamPhoto component
interface TeamPhotoProps {
    photo?: PhotoName;
    delay?: number;
    name: string;
    position: string;
}

const TeamPhoto: React.FC<TeamPhotoProps> = ({ photo, delay = 0, name, position }) => {
    return (
        <div 
            data-aos='zoom-in' 
            data-aos-duration='700' 
            data-aos-once='true' 
            data-aos-delay={delay} 
            className="group rounded-full size-[17.5rem] overflow-hidden relative shadow-2xl"
        >
            <img 
                src={getPhoto(photo)} 
                alt={`${name}'s photo`} 
                className='absolute rounded-full size-[calc(inherit+4rem)] object-cover object-center brightness-[.85] group-hover:scale-[1.15] transition-all duration-700 ease-in-out group-hover:blur-[6px] group-hover:brightness-75 cursor-pointer z-[-1]' 
            />
            <div className='absolute z-50 flex size-[inherit] items-center justify-center group-hover:scale-100 opacity-0 group-hover:opacity-100 transition-all duration-700 text-center flex-col px-[5%] gap-2 mt-4 divide-y'>
                <div className='text-white text-xl'>{name}</div>
                <div className='text-lg text-white font-extralight pt-2'>{position}</div>
            </div>
        </div>
    );
}

export default TeamPhoto;
