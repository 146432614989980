import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BsArrowRightCircle } from "react-icons/bs";
import { HiOutlineChevronDown } from "react-icons/hi2";
import logo from '../../../assets/logo.png';
import Dropdown from './Dropdown';
import './index.css';
import { toTop } from '../../../utilities/toTop';

const Navbar: React.FC = () => {
    const [nav, setNav] = useState<boolean>(false);
    const location = useLocation();

    const toggleNav = (): void => {
        setNav(prevNav => !prevNav);
    };

    // Simplified function to handle navigation and closing the mobile menu
    const handleNavClick = () => {
        toTop();
        if (nav) setNav(false);
    };

    return (
        <>
            <nav className='flex items-center justify-between px-6 py-3 bg-white shadow-md rounded-xl'>
                <div className="flex items-center">
                    <img 
                        src={logo} 
                        className='h-[55px] transition-transform duration-500 hover:scale-110 cursor-pointer' 
                        alt="Logo" 
                        onClick={toTop} 
                    />
                </div>
                
                <ul className="hidden gap-8 font-medium text-gray-700 md:flex font-manrope">
                    <Link 
                        className={`navlink transition-all duration-300 ${location.pathname === '/' ? 'navlink-active text-blue-600' : 'hover:text-blue-500'}`} 
                        to='/' 
                        onClick={handleNavClick}
                    >
                        Home
                    </Link>
                    <Link 
                        className={`navlink transition-all duration-300 ${location.pathname === '/About' ? 'navlink-active text-blue-600' : 'hover:text-blue-500'}`} 
                        to='/About' 
                        onClick={handleNavClick}
                    >
                        About Us
                    </Link>
                    
                    <Dropdown>
                        <p 
                            className={`navlink flex gap-2 items-center group cursor-default transition-all duration-300 ${location.pathname === '/Completed' || location.pathname === '/Ongoing' ? 'navlink-active text-blue-600' : 'hover:text-blue-500'}`}
                        >
                            Packages 
                            <HiOutlineChevronDown className='transition-transform duration-300 group-hover:rotate-180' />
                        </p>
                    </Dropdown>
                
                    <Link 
                        to='/Team' 
                        className={`navlink transition-all duration-300 ${location.pathname === '/Gallery' ? 'navlink-active text-blue-600' : 'hover:text-blue-500'}`} 
                        onClick={handleNavClick}
                    >
                        Our Team
                    </Link>
                </ul>
                
                <Link 
                    to='/Contact' 
                    onClick={handleNavClick} 
                    className={`navlink flex items-center justify-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-full font-bold group transition-transform duration-300 hover:bg-blue-700 hover:scale-105 shadow-md`}
                >
                    Get in touch 
                    <BsArrowRightCircle 
                        className={`transition-transform duration-300 group-hover:rotate-[-45deg] ${location.pathname === '/Contact' ? 'rotate-[-45deg]' : ''}`} 
                        size={20} 
                    />
                </Link>

                {/* Mobile Menu Button */}
                <div className='flex items-center md:hidden'>
                    <button 
                        onClick={toggleNav} 
                        className='text-gray-600 focus:outline-none'
                    >
                        <svg 
                            className="w-6 h-6" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24" 
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth="2" 
                                d="M4 6h16M4 12h16M4 18h16"
                            ></path>
                        </svg>
                    </button>
                </div>
            </nav>

            {/* Mobile Menu */}
            {nav && (
                <div className='fixed inset-0 top-0 left-0 z-30 flex flex-col bg-black/75 backdrop-blur-xl'>
                    <div className='fixed flex flex-col w-full gap-8 px-6 py-5 bg-white'>
                        <button 
                            onClick={toggleNav} 
                            className='self-end text-gray-600'
                        >
                            <svg 
                                className="w-6 h-6" 
                                fill="none" 
                                stroke="currentColor" 
                                viewBox="0 0 24 24" 
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    strokeWidth="2" 
                                    d="M6 18L18 6M6 6l12 12"
                                ></path>
                            </svg>
                        </button>

                        <ul className='flex flex-col gap-2 text-gray-700 font-manrope'>
                            <Link 
                                className={`navlink text-xl py-2 px-4 ${location.pathname === '/' ? 'bg-gray-200' : ''}`} 
                                to='/' 
                                onClick={handleNavClick}
                            >
                                Home
                            </Link>
                            <Link 
                                className={`navlink text-xl py-2 px-4 ${location.pathname === '/About' ? 'bg-gray-200' : ''}`} 
                                to='/About' 
                                onClick={handleNavClick}
                            >
                                About Us
                            </Link>
                            
                            <Dropdown>
                                <p 
                                    className={`navlink text-xl py-2 px-4 flex gap-2 items-center ${location.pathname === '/Completed' || location.pathname === '/Ongoing' ? 'bg-gray-200' : ''}`}
                                >
                                    Packages 
                                    <HiOutlineChevronDown className='transition-transform duration-300 group-hover:rotate-180' />
                                </p>
                            </Dropdown>
                        
                            <Link 
                                className={`navlink text-xl py-2 px-4 ${location.pathname === '/Team' ? 'bg-gray-200' : ''}`} 
                                to='/Team' 
                                onClick={handleNavClick}
                            >
                                Our Team
                            </Link>
                            <Link 
                                className={`navlink text-xl py-2 px-4 ${location.pathname === '/Contact' ? 'bg-gray-200' : ''}`} 
                                to='/Contact' 
                                onClick={handleNavClick}
                            >
                                Contact
                            </Link>
                        </ul>
                    </div>
                </div>
            )}
        </>
    );
};

export default Navbar;
