import React from 'react';
import './about.css'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import FlagIcon from '@mui/icons-material/Flag';
import PeopleIcon from '@mui/icons-material/People';
import StarIcon from '@mui/icons-material/Star';
import ConstructionIcon from '@mui/icons-material/Construction';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

const Timeline: React.FC = () => {
  return (
    <div className='mt-[50px] flex items-center bg-customGray pt-[50px] pb-[50px]'>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="2021"
          iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
          icon={<FlagIcon />}
        >
          <h3 className="vertical-timeline-element-title text-xl font-bold text-red-700 transition-transform transform hover:scale-105 hover:shadow-lg p-4">Our Humble Beginnings</h3>
          <p className='transition-transform transform hover:scale-105 hover:shadow-lg p-4'>
          CV Business Branding, established in early 2021, quickly became a trusted partner for businesses seeking innovative digital marketing solutions.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="April 2021"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<PeopleIcon />}
        >
    <h3 className="text-xl font-bold text-blue-700 transition-transform transform hover:scale-105 hover:shadow-lg p-4">
  Dedication
</h3>
<p className="transition-transform transform hover:scale-105 hover:shadow-lg p-4">
  In April 2021, CV Business Branding formed a strategic partnership with Paripati Khabar, a leading news channel, to leverage their combined expertise in digital marketing and media. This collaboration aimed to amplify brand visibility and reach, allowing both companies to deliver more impactful content and marketing campaigns.
</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="2022"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<ConstructionIcon />}
        >
          <h3 className="vertical-timeline-element-title text-xl font-bold text-blue-700 transition-transform transform hover:scale-105 hover:shadow-lg p-4">Growth</h3>
          <p className='transition-transform transform hover:scale-105 hover:shadow-lg p-4'>
          In 2022, CV Business Branding expanded its operations by launching a new branch called CV Media Skills, focused on providing students with essential digital skills. This initiative reflects the company's commitment to fostering education and equipping the next generation with the tools they need to thrive in a digital-first world.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="2023"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<PersonAddAlt1Icon />}
        >
          <h3 className="vertical-timeline-element-title text-xl font-bold text-red-700 transition-transform transform hover:scale-105 hover:shadow-lg p-4">Inspirational</h3>
          <p className='transition-transform transform hover:scale-105 hover:shadow-lg p-4'>
          In 2023, CV Business Branding strengthened its impact by tying up with CV Media Skills, inspiring numerous students to explore and excel in digital marketing. This partnership played a crucial role in shaping the careers of aspiring digital marketers, equipping them with the skills and insights needed for success in the ever-evolving digital landscape.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="Thank You"
          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
          icon={<StarIcon />}
        >
          <h3 className="vertical-timeline-element-title text-xl font-bold text-blue-700 transition-transform transform hover:scale-105 hover:shadow-lg p-4">Be Part of Our Story</h3>
          <p className='transition-transform transform hover:scale-105 hover:shadow-lg p-4'>
          Join CV Business Branding in our journey of innovation and excellence. As we shape the future of digital marketing, your involvement becomes a key chapter in our success. Together, let's craft impactful strategies and inspire new heights.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};

export default Timeline;
