import React, { useState, useEffect, ComponentType } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

// Define the type for the Protected component props
interface ProtectedProps extends RouteProps {
  component: ComponentType<any>; // React component type
}

const Protected: React.FC<ProtectedProps> = ({ component: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    // Function to check authentication status
    const checkAuthStatus = () => {
      const token = localStorage.getItem('token');
      setIsAuthenticated(!!token); 
    };

    checkAuthStatus();
  }, []); 

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Show a loading indicator while checking authentication
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/admin/login" />
      }
    />
  );
};

export default Protected;
