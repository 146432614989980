import { useState, ChangeEvent, FormEvent } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ContactFormData {
    username: string;
    email: string;
    number: string;
    message: string;
}

const defaultContactFormData: ContactFormData = {
    username: "",
    email: "",
    number: "",
    message: "",
};

const Contact = () => {
    const [contact, setContact] = useState<ContactFormData>(defaultContactFormData);

    const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setContact({
            ...contact,
            [name]: value,
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const response = await fetch(`https://business-branding-4t1h.onrender.com/api/form/contact`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(contact),
            });

            if (response.ok) {
                setContact(defaultContactFormData);
                const data = await response.json();
                console.log('Response Data:', data); // Log the response data
                toast.success('Message sent successfully!');
            } else {
                console.error('Response Error:', response.status, response.statusText); // Log response error details
                toast.error('Message not sent. Please try again.');
            }
        } catch (error) {
            console.error('Fetch Error:', error); // Log fetch error details
            toast.error('Message not sent. Please try again.');
        }
    };

    return (
        <div className="mt-[60px] py-12 flex flex-col items-center">
            <ToastContainer />

            <h1 className="text-blue-700"><span className="text-primary">Contact</span> us</h1>
            <div className="py-12 w-full px-[5%]">
                <div className="flex flex-col w-full gap-24 md:gap-12 md:flex-row">
                    <div className='flex flex-col order-2 md:w-1/2 md:order-1'>
                        <div className="overflow-hidden bg-white rounded-lg shadow-md">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3531.360604102659!2d85.32028704535549!3d27.737021503966023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb190079bfbff9%3A0xd233c622eaf35579!2sCv%20Media%20Skills!5e0!3m2!1sen!2snp!4v1723806113425!5m2!1sen!2snp"
                                width="100%"
                                style={{ border: 0 }}
                                allowFullScreen={true}
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                className="rounded-lg md:h-[1000px] h-[450px]"
                            ></iframe>
                        </div>
                    </div>
                    <div className='flex flex-col order-1 gap-12 md:w-1/2 md:order-2'>
                        <div className='flex flex-col w-full gap-4 text-lg text-justify font-manrope'>
                            <div className='pt-4 text-xl'>
                                Whether you have a question, need assistance, or want to discuss how we can help your business grow, we're here for you! At CV Business Branding, our team is ready to provide the support and expertise you need to succeed in the digital landscape.
                            </div>
                        </div>
                        <form className="flex flex-col justify-between md:h-full md:pb-0" onSubmit={handleSubmit}>
                            <section className="flex flex-col gap-4 mt-4 mb-8 text-lg sm:mb-12 font-manrope">
                                <h5 className="pb-12 uppercase">Simply fill out <span className="text-primary">this form</span></h5>
                                <div className="flex flex-col justify-between gap-4 md:gap-8">
                                    <div className="flex flex-col w-full">
                                        <h5 className="flex pb-2 text-lg font-normal">Name:</h5>
                                        <input
                                            name="username"
                                            value={contact.username}
                                            onChange={handleInput}
                                            placeholder="Enter your name"
                                            className="w-full p-2 transition-all duration-300 ease-in-out transform border-b-2 rounded-md shadow-md outline-none focus:border-primary focus:pb-3 md:p-3 font-manrope text-neutral-700 focus:ring-2 focus:ring-primary focus:scale-105 hover:shadow-lg hover:scale-105"
                                            type="text"
                                        />
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <h5 className="flex pb-2 text-lg font-normal">Email:</h5>
                                        <input
                                            name="email"
                                            value={contact.email}
                                            onChange={handleInput}
                                            placeholder="Enter your email"
                                            className="w-full p-2 transition-all duration-300 ease-in-out transform border-b-2 rounded-md shadow-md outline-none focus:border-primary focus:pb-3 md:p-3 font-manrope text-neutral-700 focus:ring-2 focus:ring-primary focus:scale-105 hover:shadow-lg hover:scale-105"
                                            type="email"
                                        />
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <h5 className="flex pb-2 text-lg font-normal">Phone:</h5>
                                        <input
                                            name="number"
                                            value={contact.number}
                                            onChange={handleInput}
                                            placeholder="Enter your number"
                                            className="w-full p-2 transition-all duration-300 ease-in-out transform border-b-2 rounded-md shadow-md outline-none focus:border-primary focus:pb-3 md:p-3 font-manrope text-neutral-700 focus:ring-2 focus:ring-primary focus:scale-105 hover:shadow-lg hover:scale-105"
                                            type="number"
                                        />
                                    </div>
                                    <div className="flex flex-col w-full gap-2">
                                        <h5 className="flex pb-2 text-lg font-normal">What is the reason for your inquiry?</h5>
                                        <textarea
                                            name="message"
                                            value={contact.message}
                                            onChange={handleInput}
                                            rows={6}
                                            placeholder="..."
                                            className="w-full p-2 transition-all duration-300 ease-in-out transform border-b-2 rounded-md shadow-md outline-none focus:border-primary focus:pb-3 md:p-3 font-manrope text-neutral-700 focus:ring-2 focus:ring-primary focus:scale-105 hover:shadow-lg hover:scale-105"
                                        />
                                    </div>
                                </div>
                            </section>
                            <input
                                type="submit"
                                value="Submit"
                                className="py-3 font-semibold text-white transition-all duration-300 ease-in-out transform rounded-md shadow-md cursor-pointer bg-primary hover:brightness-125 hover:shadow-lg hover:scale-105"
                            />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
