import AdminNavbar from '../admin-global/admin-navbar'; 
import AdminRoutes from "../routes/admin-routes";

const AdminLayout = () => {
    return (
        <div className="flex h-screen">

            <AdminNavbar />
            <div className="flex-1 p-6 bg-gray-100">
                <AdminRoutes />
            </div>
        </div>
    );
};

export default AdminLayout;