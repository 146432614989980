// Service Images
import serviceCard1 from '../assets/services/1.png'
import serviceCard2 from '../assets/services/2.png'
import serviceCard3 from '../assets/services/3.png'
import serviceCard4 from '../assets/services/4.png'
import serviceCard5 from '../assets/services/5.png'
import serviceCard6 from '../assets/services/6.png'

//Completed Case Images
// import completedProject1 from '../assets/completed/1.png'
// import completedProject2 from '../assets/completed/2.png'
// import completedProject3 from '../assets/completed/3.png'
// import completedProject4 from '../assets/completed/4.png'

export const completedCases = [
    {
        title: 'Startup Package',
        alt: 'RS45000 per Month',
        url: serviceCard1,
        list: ['Basic Optimization Plan ', 'Professional Logo Design', '3 Content Per Day', 'Website Management', 'Simple SEO', 'Social Media Markerting', 'Email Marketing', 'Business Consultant'] as string[],
        delay: 100,
    },
    {
        title: 'Basic Package',
        alt: 'Description of Service 2',
        url: serviceCard2,
        list: ['Professional Dashboard', 'One Time Professional Photo Shoot', 'Security', 'Consultant', 'Excluded "TEA" (Travel with Entire Accomodation) ' , 'Simple Logo Design', 'Google Business Profile', 'Social Media Handling'] as string[],
        delay: 200,
    },
    {
        title: 'CV Package',
        alt: 'Description of Service 2',
        url: serviceCard3,
        list: ['Content Writing','Business Consultant' ,'Business Boosting'] as string[],
        delay: 300,
    },
    {
        title: 'Standard Package',
        alt: 'Description of Service 2',
        url: serviceCard4,
        list: ['3 Reels Per Day', '3 Graphics Post Per day', 'Basic Maintainance', 'Detail Design', 'Boosting (Personal Charge)', 'Website Development ', 'Business Consultant'] as string[],
        delay: 0,
    },
];

export const services = [
    {
        title: 'Startup Package',
        title1: 'RS45000 per Month',
        alt: 'Description of Service 1',
        url: serviceCard1,
        list: ['Basic Optimization Plan ', 'Professional Logo Design', '3 Content Per Day', 'Website Management', 'Simple SEO', 'Social Media Markerting', 'Email Marketing', 'Business Consultant'] as string[],
        delay: 100,
    },
    {
        title: 'Basic Optimization Package',
        title1: 'Rs13000 in  11 Days',
        alt: 'bASIC OP plans details ',
        url: serviceCard2,
        list: ['Professional Dashboard', 'One Time Professional Photo Shoot', 'Security', 'Consultant', 'Excluded "TEA" (Travel with Entire Accomodation) ' , 'Simple Logo Design', 'Google Business Profile', 'Social Media Handling'] as string[],
        delay: 200,
    },
    {
        title: 'Gold Package',
        title1: 'Rs130000 per month',
        alt: 'Description of Service 2',
        url: serviceCard3,
        list: ['Basic & Startup package plus', '3 Reels Per Day', '3 Graphics Post Per day', 'Basic Maintainance', 'Detail Design', 'Boosting (Personal Charge)', 'Website Development ', 'Business Consultant'] as string[],
        delay: 300,
    },
    {
        title: 'Platinium Package',
        title1: 'Rs1000000 per month',
        alt: 'Description of Service 2',
        url: serviceCard4,
        list: ['Basic, Startup & Gold package plus', 'Content Writer', 'Graphic Designer', 'Professional Website', 'Meta Business Suite', 'Animated Website Development', 'Business Consultant'] as string[],
        delay: 0,
    },
    {
        title: 'Advertisement Package',
        title1: 'Price Mention Below',
        alt: 'Description of Service 2',
        url: serviceCard5,
        list: ['15 days package - 85 rupees per minute', '1 month package - 70 rupees per minute', '6 month package - 55 rupees per minute','1 year package - 45 ruppes per minute'] as string[],
        delay: 0,
    },
    {
        title: 'Special Package',
    title1: 'Rs100000 per month',
        alt: 'Description of Service 2',
        url: serviceCard6,
        list: [ 'Content Writing','Business Consultant' ,'Business Boosting'] as string[],
        delay: 0,
    },
];

export const galleryImages = [
    {
        "url": "https://source.unsplash.com/ztpUS4N1xhY"
    },
    {
        "url": "https://source.unsplash.com/mPFSPqZOO7s"
    },
    {
        "url": "https://source.unsplash.com/uPEnxrdSKIw"
    },
    {
        "url": "https://source.unsplash.com/hFKZ5-OT9Ys"
    },
    {
        "url": "https://source.unsplash.com/xoTt2fjs7d0"
    },
    {
        "url": "https://source.unsplash.com/Vc2dD4l57og"
    },
    {
        "url": "https://source.unsplash.com/hqnQWmIt3cY"
    },
    {
        "url": "https://source.unsplash.com/NTGg2rtWDwg"
    },
    {
        "url": "https://source.unsplash.com/uyX3qAQhZVA"
    },
    {
        "url": "https://source.unsplash.com/LV-NvIcA-Gg"
    },
    {
        "url": "https://source.unsplash.com/0qnRfgnZIsI"
    },
    {
        "url": "https://source.unsplash.com/mpwF3Mv2UaU"
    },
    {
        "url": "https://source.unsplash.com/gfMWhkDCwYM"
    },
    {
        "url": "https://source.unsplash.com/8KtqjrskUg8"
    },
    {
        "url": "https://source.unsplash.com/qAaGXj-AOv4"
    }
];
