import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const Navbar: React.FC = () => {
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false); // State to handle mobile menu toggle

    const handleLogout = () => {
        localStorage.removeItem('token'); 
        history.push('/admin/login');
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen); 
    };

    return (
        <nav className="bg-gray-900 shadow-md">
            <div className="container flex items-center justify-between px-4 py-3 mx-auto">
                {/* Logo */}
                <div className="text-2xl font-bold text-white">
                    <a href="/admin/dashboard" className="transition-colors duration-300 hover:text-yellow-400">AdminPanel</a>
                </div>

                {/* Hamburger Menu for mobile */}
                <div className="lg:hidden">
                    <button 
                        onClick={toggleMenu} 
                        className="text-white transition-colors duration-300 hover:text-yellow-400 focus:outline-none"
                    >
                        <svg 
                            className="w-6 h-6" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24" 
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            {isOpen ? (
                                <path 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    strokeWidth={2} 
                                    d="M6 18L18 6M6 6l12 12" 
                                />
                            ) : (
                                <path 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    strokeWidth={2} 
                                    d="M4 6h16M4 12h16m-7 6h7" 
                                />
                            )}
                        </svg>
                    </button>
                </div>

                {/* Desktop Links */}
                <div className="hidden space-x-6 lg:flex">
                    <a href="/admin/dashboard" className="text-white transition-colors duration-300 hover:text-yellow-400">
                        Dashboard
                    </a>
                    <a href="/admin/managecontact" className="text-white transition-colors duration-300 hover:text-yellow-400">
                        Contacts
                    </a>
                    <button
                        onClick={handleLogout}
                        className="text-white transition-colors duration-300 hover:text-yellow-400 focus:outline-none"
                        type="button"
                    >
                        Logout
                    </button>
                </div>
            </div>

            {/* Mobile Menu */}
            {isOpen && (
                <div className="bg-gray-800 border-t border-gray-700 lg:hidden">
                    <div className="px-2 py-3 space-y-1">
                        <a href="/admin/dashboard" className="block px-3 py-2 text-white transition-colors duration-300 rounded-md hover:bg-gray-700">
                            Dashboard
                        </a>
                        <a href="/admin/managecontact" className="block px-3 py-2 text-white transition-colors duration-300 rounded-md hover:bg-gray-700">
                            Contacts
                        </a>
                        <button
                            onClick={handleLogout}
                            className="block w-full px-3 py-2 text-left text-white transition-colors duration-300 rounded-md hover:bg-gray-700"
                            type="button"
                        >
                            Logout
                        </button>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
