import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Protected from './protected-routes';
import AdminDashboard from '../admin/Admin-Dashboard'; // Example component for dashboard
import ManageContact from '../admin/Manage-contact'; // Example component for managing contacts
import AdminLogin from '../admin/admin-login'; // Example login component

const AdminRoutes: React.FC = () => {
  return (
    <Router>
      <Switch>
        {/* Protected routes */}
        <Protected exact path="/admin/dashboard" component={AdminDashboard} />
        <Protected exact path="/admin/managecontact" component={ManageContact} />

        {/* Unprotected route */}
        <Route exact path="/admin/login" component={AdminLogin} />

        {/* Redirect all other paths to the dashboard */}
        <Route path="*">
          <Redirect to="/admin/login
          " />
        </Route>
      </Switch>
    </Router>
  );
};

export default AdminRoutes;
