import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion";

const AccordionWhyUs: React.FC = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <Accordion type="multiple"   className="w-full bg-neutral items-center justify-center px-[5%] flex md:flex-row flex-col md:gap-16  py-[80px]">
            <AccordionItem value="item-1" data-aos="fade-up">
                <AccordionTrigger className="text-2xl text-blue-600 shadow-md shadow-blue-100">
                    What services do you offer?
                </AccordionTrigger>
                <AccordionContent className="py-8 text-xl tracking-tight text-neutral-500 text-justify text-pretty shadow-md shadow-blue-100">
                    We provide SEO, social media marketing, content creation, email marketing, and paid ads to help your brand grow online.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-2" data-aos="fade-up" data-aos-delay="100">
                <AccordionTrigger className="text-2xl text-start text-blue-600 shadow-md shadow-blue-100">
                    How can your services help my business?
                </AccordionTrigger>
                <AccordionContent className="py-8 text-xl tracking-tight text-neutral-500 text-justify text-pretty shadow-md shadow-blue-100">
                    We boost your online visibility, attract leads, and convert them into customers using data-driven strategies.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-3" data-aos="fade-up" data-aos-delay="200">
                <AccordionTrigger className="text-2xl text-blue-600 shadow-md shadow-blue-100">
                    How do you tailor your strategies?
                </AccordionTrigger>
                <AccordionContent className="py-8 text-xl tracking-tight text-neutral-500 text-justify text-pretty shadow-md shadow-blue-100">
                    We analyze your business goals and audience to create personalized, effective marketing strategies.
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    );
};

export default AccordionWhyUs;
