import './index.css'
import { services } from '../../data'

const LandingServices = () => {
    return (
      <div id='serviceAnchor' className="flex flex-col min-h-screen bg-neutral px-[5%] w-full pb-24">
      <div data-aos-duration='700' data-aos='fade-up' className='flex flex-col items-center w-full gap-4 py-20 mt-4'>
        <h5 className='text-primary'>Our Services</h5>
        {/* <p className='text-lg text-center font-manrope'>20 Years of Excellence in Client-Oriented Infrastructure Solutions</p> */}
      </div>
      <div className='grid w-full grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-12 gap-y-16'>
        {services.map((service) => (
          <div 
            data-aos-duration='700' 
            data-aos='fade-up' 
            data-aos-delay={service.delay} 
            className='flex flex-col overflow-hidden bg-white shadow-xl rounded-3xl group transform transition-transform duration-500 hover:scale-105' 
            key={service.title}
          >
            <div className='w-full overflow-hidden'>
              <img 
                src={service.url} 
                alt={service.alt} 
                className='flex w-full duration-500 group-hover:scale-110' 
              />
            </div>
            <h3 className='flex justify-center text-red-700 w-full py-4 mt-4 text-xl font-semibold font-poppins px-[7.5%] text-center'>
              {service.title}
            </h3>
            <h2 
              className='flex justify-center text-blue-800 w-full pb-4 mt-1 text-xl font-bold font-poppins px-[7.5%] text-center transition-transform duration-300 hover:scale-95 hover:shadow-xl'
            >
              {service.title1}
            </h2>
            <ul className='text-base text-blue-700 font-manrope px-[7.5%] text-center divide-y pb-8'>
              {service.list.map((item) => (
                <li 
                  className='py-2 transform transition-transform duration-300 hover:scale-95 hover:shadow-lg' 
                  key={item}
                >
                  {item}
                </li>
              ))}
            </ul>
            <div className='flex justify-center pb-8'>
              <button
                className='bg-blue-600 text-white px-6 py-3 rounded-full font-semibold shadow-lg transition duration-500 ease-in-out transform hover:bg-green-700 hover:-translate-y-1 hover:scale-110 hover:shadow-xl'
              >
                Book Now
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
    
    )
}

export default LandingServices
