import React, { useState, useEffect } from 'react';

interface AdminDetails {
    username: string;
    email: string;
    phone: string;
    password?: string; // Optional for password updates
}

const AdminDashboard: React.FC = () => {
    const [adminDetails, setAdminDetails] = useState<AdminDetails | null>(null);
    const [formData, setFormData] = useState<AdminDetails>({
        username: '',
        email: '',
        phone: '',
        password: '', // Include password field
    });
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);

    useEffect(() => {
        fetchAdminDetails();
    }, []);

    const fetchAdminDetails = async () => {
        try {
            const response = await fetch('https://business-branding-4t1h.onrender.com/api/admin/details'); // Adjust the path if needed
            if (!response.ok) {
                throw new Error('Failed to fetch admin details');
            }
            const data: AdminDetails = await response.json();
            setAdminDetails(data);
            setFormData(data);
        } catch (error) {
            setError('Error fetching admin details');
            console.error('Error fetching admin details:', error);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await fetch('https://business-branding-4t1h.onrender.com/api/admin/details', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (!response.ok) {
                throw new Error('Failed to update admin details');
            }
            const updatedAdmin: AdminDetails = await response.json();
            setAdminDetails(updatedAdmin);
            setSuccess('Admin details updated successfully');
        } catch (error) {
            setError('Error updating admin details');
            console.error('Error updating admin details:', error);
        }
    };

    return (
        <div className="container p-6 mx-auto bg-white rounded-lg shadow-lg">
            <h2 className="mb-6 text-2xl font-extrabold text-center text-blue-700">
                Admin Dashboard
            </h2>
            {error && <p className="mb-4 text-center text-red-500">{error}</p>}
            {success && <p className="mb-4 text-center text-green-500">{success}</p>}
            {adminDetails ? (
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Username</label>
                        <input
                            type="text"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Phone</label>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Password</label>
                        <input
                            type="password"
                            name="password"
                            value={formData.password || ''}
                            onChange={handleChange}
                            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full px-4 py-2 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700"
                    >
                        Update Details
                    </button>
                </form>
            ) : (
                <p className="text-center text-gray-500">Loading admin details...</p>
            )}
        </div>
    );
};

export default AdminDashboard;
