
import TeamHero from '@/components/Team/TeamHero'
import TeamMembers from '@/components/Team/TeamMembers'
const Team = () => {
  return (
    <div>
      <TeamHero />
      <TeamMembers/>
    </div>
  )
}

export default Team
