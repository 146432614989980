import React from 'react';
import Footer from './components/global/Footer/Footer';
import Navbar from './components/global/Navbar/Navbar';
import Landing from './pages/Landing';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Team from './pages/Team';
import About from './pages/About';
import OngoingProjects from './pages/OngoingProjects';
import CompletedProjects from './pages/CompletedProjects';
import Contact from './components/Contact/Contact';
import adminlogin from './components/admin/admin-login';
import AdminLayout from './components/layout/admin-layout';
// Initialize AOS (Animate On Scroll)
AOS.init();

// Define AdminRoutes component
const AdminRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/admin/login" component={adminlogin} />
      <Route path="/admin" component={AdminLayout} />
      {/* Define additional admin routes here */}
    </Switch>
  );
};

const MainRoutes: React.FC = () => {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route path="/about" component={About} />
        <Route path="/ongoing" component={OngoingProjects} />
        <Route path="/completed" component={CompletedProjects} />
        <Route path="/team" component={Team} />
        <Route path="/contact" component={Contact} />
       
      </Switch>
      <Footer />
    </Router>
  )
}

// Define App component with main and admin routes
const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/admin" component={AdminRoutes} />
        <Route path="/" component={MainRoutes} />
      </Switch>
    </Router >
  );
};

export default App;
