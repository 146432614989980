import OngoingHero from "@/components/Ongoing/OngoingHero"

const OngoingProjects = () => {
    return (
        <>
        <OngoingHero />
        </>
    )
}

export default OngoingProjects
