
import AboutHero from "../components/About/AboutHero"
import AboutTimeline from '../components/About/AboutTimeline'
import AboutOrganogram from "../components/About/AboutOrganogram"
import AboutStats from "../components/About/AboutStats"
import LandingPortfolio from "../components/Landing/LandingPortfolio"

const About = () => {
    return (
        <>
            <AboutHero />
            <AboutTimeline />
            <AboutStats />
            <LandingPortfolio />
            <AboutOrganogram /> 
   
        </>
    )
}

export default About 
