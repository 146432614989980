import LandingHero from "../components/Landing/LandingHero"
import LandingMissionVision from "../components/Landing/LandingMissionVision"
import LandingNewsletter from "../components/Landing/LandingNewsletter"
import LandingPortfolio from "../components/Landing/LandingPortfolio"
import LandingServices from "../components/Landing/LandingServices"
import Card from '../components/Landing/Card'
import AccordionWhyUs from "../components/Landing/AccordionWhyUs"









const Landing = () => {
    return (
        <>
            <LandingHero />
            
            <LandingPortfolio />
            <AccordionWhyUs />
             <Card />
             <LandingServices />
             <LandingMissionVision />
                  

          

             <LandingNewsletter /> 
             
            
        </>
    )
}

export default Landing
