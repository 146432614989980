import './index.css';

import 'aos/dist/aos.css'; // Import AOS styles

const LandingMissionVision = () => {
    return (
        <div className='flex md:flex-row flex-col w-full divide-y md:divide-y-0 px-[5%] bg-primary py-24 text-white md:divide-x'>
            <div 
    data-aos='fade-up'
    data-aos-duration='700'
    data-aos-delay='100'
    className='flex flex-col items-center gap-4 pb-12 md:px-12 md:w-1/2 md:pb-0 transition-transform duration-500 hover:scale-95 hover:shadow-2xl'
    style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} // Initial box shadow
>
    <h5 className='flex justify-center w-full text-2xl font-bold font-manrope'>Our Mission</h5>
    <p className='flex font-light text-center text-lg pb-4 font-manrope'>
        "Empowering businesses with innovative digital solutions to drive growth and engage audiences effectively."
    </p>
</div>

<div 
    data-aos='fade-up'
    data-aos-duration='700'
    data-aos-delay='200'
    className='flex flex-col items-center gap-4 pt-12 md:px-12 md:w-1/2 md:pt-0 transition-transform duration-500 hover:scale-95 hover:shadow-2xl'
    style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} // Initial box shadow
>
    <h5 className='flex justify-center w-full text-2xl font-bold font-manrope'>Our Vision</h5>
    <p className='flex font-light text-center pb-4 text-lg font-manrope'>
        "To be a leading digital marketing partner, renowned for transforming brands through cutting-edge strategies and creative excellence."
    </p>
</div>
        </div>
    );
};

export default LandingMissionVision;
