// import { IoMailOutline } from "react-icons/io5";
// import { SlLocationPin } from "react-icons/sl";
// import { FiPhone } from "react-icons/fi";
// import logo from '../../../assets/logo.png'
// import { FaArrowUp } from "react-icons/fa";
// import { toTopSmooth } from "@/utilities/toTop";
import logo from '../../../assets/logo.png'

const Footer = () => {
    return (
        
        <footer className="font-sans bg-blue-800 dark:bg-gray-900">
  <div className="container px-6 py-12 mx-auto">
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
      <div className="sm:col-span-2">
        <h1 className="max-w-lg text-2xl font-semibold tracking-tight text-white xl:text-3xl dark:text-white">
          Stay Tuned for Updates
        </h1>
        <div className="flex items-center pt-10 ml-4">
          <img src={logo} className='h-[55px] transition-transform duration-500 hover:scale-110' alt="Logo" />
        </div>

        {/* <div className="flex flex-col items-center mt-6 space-y-3 md:flex-row md:space-y-0">
          <input
            id="email"
            type="email"
            className="w-full px-4 py-3 text-gray-700 bg-white border rounded-md dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-400 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-blue-300 md:w-auto"
            placeholder="Your Email Address"
          />
          <button className="w-full px-8 py-3 mt-4 text-sm font-medium text-white transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 md:w-auto md:ml-4 md:mt-0 focus:outline-none focus:ring focus:ring-blue-400 focus:ring-opacity-80">
            Subscribe
          </button>
        </div> */}
      </div>

      {/* Quick Links */}
      <div>
        <p className="font-semibold text-white dark:text-white">Quick Links</p>
        <div className="flex flex-col items-start mt-5 space-y-3">
          {["Home", "About Us", "Our Services", "Team"].map((link) => (
            <a
              href="#"
              key={link}
              className="text-white transition-colors duration-300 hover:text-blue-500 hover:underline dark:text-gray-300 dark:hover:text-blue-400"
            >
              {link}
            </a>
          ))}
        </div>
      </div>

      {/* Industries */}
      <div>
        <p className="font-semibold text-white dark:text-white">Industries</p>
        <div className="flex flex-col items-start mt-5 space-y-3">
          {["Retail & E-Commerce", "Information Technology", "Finance & Insurance"].map((industry) => (
            <a
              href="#"
              key={industry}
              className="text-white transition-colors duration-300 hover:text-blue-500 hover:underline dark:text-gray-300 dark:hover:text-blue-400"
            >
              {industry}
            </a>
          ))}
        </div>
      </div>
    </div>

    {/* Divider */}
    <hr className="my-8 border-t border-gray-200 dark:border-gray-700" />

    {/* Download and Social Section */}
    <div className="sm:flex sm:items-center sm:justify-between">
      <div className="flex flex-wrap gap-4 sm:flex-1">
        <a href="#" className="block">
          <img
            src="https://www.svgrepo.com/show/303139/google-play-badge-logo.svg"
            width="130"
            height="110"
            alt="Download on Google Play"
            className="hover:opacity-90"
          />
        </a>
        <a href="#" className="block">
          <img
            src="https://www.svgrepo.com/show/303128/download-on-the-app-store-apple-logo.svg"
            width="130"
            height="110"
            alt="Download on App Store"
            className="hover:opacity-90"
          />
        </a>
      </div>

      {/* Social Media */}
      <div className="flex gap-4 mt-6 sm:mt-0">
        {[
          { src: "https://www.svgrepo.com/show/303114/facebook-3-logo.svg", alt: "Facebook" },
          { src: "https://www.svgrepo.com/show/303115/twitter-3-logo.svg", alt: "Twitter" },
          { src: "https://www.svgrepo.com/show/303145/instagram-2-1-logo.svg", alt: "Instagram" },
        ].map((icon) => (
          <a href="#" key={icon.alt}>
            <img
              src={icon.src}
              width="30"
              height="30"
              alt={icon.alt}
              className="transition-transform transform hover:scale-110"
            />
          </a>
        ))}
      </div>
    </div>

    <p className="mt-8 text-center text-white dark:text-gray-300">
      © 2024 Cv Media Skills All rights reserved.
    </p>
  </div>
</footer>

      
        
    )
}

export default Footer