import about from '../../assets/about.png'

const AboutHero = () => {
    return (
        <div className="mt-[60px] flex min-h-[calc(100vh-60px)] px-[5%]">
            <div className='flex flex-col md:gap-16 md:flex-row'>
                <div className='flex flex-col order-2 pt-12 divide-y md:w-1/2 md:order-1'>
                    <div data-aos='fade-up' data-aos-duration='700' data-aos-delay='100' className='flex flex-col pb-4'>
                        <h1 className='text-blue-700'>Unlock Your Brand</h1>
                        <h1 className='leading-[1.25] text-red-700'>With <span className='text-primary'>CV Business Branding</span> </h1>
                    </div>
                    <p data-aos='fade-up' data-aos-duration='700' data-aos-delay='200' className='flex flex-col gap-4 pt-4 text-lg font-manrope'>
                        <div>Elevate your brand with CV Business Branding. We specialize in digital marketing, offering tailored services like professional logo design, website management, and social media marketing. </div>
                        <div> Our expertise in SEO and content creation ensures your brand stands out. Partner with us to unlock your brand's full potential. Let's grow together!</div>
                    </p>
                </div>
                <div className='flex order-1 md:w-1/2 md:order-2'>
                    <img data-aos='fade-up' data-aos-duration='700' data-aos-delay='300' className='flex object-cover object-center' src={about} alt="" />
                </div>
            </div>
        </div>
    )
}

export default AboutHero