import React from 'react';
 import teamImg from '../../assets/demo2.webp'

const TeamHero: React.FC = () => {
    return (
        <div className='flex min-h-screen px-[10%] items-center justify-center'>
            <div className="flex lg:flex-row flex-col w-full h-max mt-[180px] md:mt-[80px]">
            <div className='flex flex-col order-2 pt-12 divide-y md:w-1/2 md:order-1'>
                    <div data-aos='fade-up' data-aos-duration='700' data-aos-delay='100' className='flex flex-col pb-4 px-4'>
                        <h1 className='text-blue-700'>Meet the Visionaries Behind <span className='text-red-700'>CV Business Branding</span></h1>
                        <p className='leading-[1.25]  text-xl  pt-4 '>Our team at CV Business Branding is a powerhouse of creative thinkers and experts. United by a shared vision, we drive innovation and craft compelling brand stories, redefining excellence in digital marketing. </p>
                    </div>
                    
                </div>
                <div 
                    data-aos='fade-up' 
                    data-aos-delay='300' 
                    data-aos-once='true' 
                    data-aos-duration='700' 
                    className="lg:w-1/2 relative pb-14 lg:flex-grow order-1 md:order-2"
                >
                    <img 
                        src={teamImg} 
                        className='rounded-3xl object-cover brightness-75 w-full h-full flex flex-1' 
                        alt="image of team" 
                    />
                </div>
            </div>
        </div>
    );
}

export default TeamHero;
